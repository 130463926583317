@font-face {
	font-family: "Malibu Handwritten 3";
	src: url("./fonts/MalibuHandwritten3-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Cubano Nuevo Italic";
	src: url("./fonts/CubanoNuevoItalic.otf") format("opentype");
}



@font-face {
	font-family: "Cubano Nuevo Regular";
	src: url("./fonts/CubanoNuevoRegular.otf") format("opentype");
}

@font-face {
	font-family: "Geoxe bold";
	src: url("./fonts/geoxe3_bold.ttf") format("truetype");
}


html, body {
	font-family: "Cubano Nuevo Italic";
	

}
h1,h2,h3{
	font-family: "Cubano Nuevo Italic";
}
p{
	font-family: "Geoxe bold";
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}